import { states, statesAvailable, statesNotServe } from './states'

export const isValidForEp02 = (value1, value2, value3) => {
  if (statesNotServe.includes(value1)) {
    return false
  }

  if ((!value1 || statesAvailable.includes(value1)) && ['exogenous', 'combination'].includes(value2)) {
    return false
  }

  return true
}

export const isValidForOralTrtPlus = (value1, value2, value3) => {
  if (statesNotServe.includes(value1)) {
    return false
  }

  if (value1 && !statesAvailable.includes(value1)) {
    return false
  }

  if (value2 === 'boost') {
    return false
  }

  if (value2 === 'exogenous' && (!value3 || value3 === 'no')) {
    return false
  }

  if (value1 && !value2 && value3 === 'no') {
    return false
  }

  return true
}

export const isValidForOralTrt = (value1, value2, value3) => {
  if (statesNotServe.includes(value1)) {
    return false
  }

  if (value1 && !statesAvailable.includes(value1)) {
    return false
  }

  if (value2 && value2 !== 'exogenous') {
    return false
  }

  if ((value1 || value2) && value3 === 'yes') {
    return false
  }

  return true
}

export const getRecommendation = (validForEp02, validForOralTrtPlus, validForOralTrt, value1, value2, value3) => {
  if (validForEp02 && validForOralTrtPlus && validForOralTrt) {
    return 'For clients who have not yet tried TRT, and have not been diagnosed with primary hypogonadism, we recommend starting with our EP-02 Protocol.'
  }

  if (validForEp02 && validForOralTrtPlus && !validForOralTrt) {
    return 'For clients who have not yet tried TRT, and have not been diagnosed with primary hypogonadism, we recommend starting with our EP-02 Protocol. If the results are not as pronounced as you\'d like, you can upgrade to Oral TRT+ at any time'
  }

  if (validForEp02 && !validForOralTrtPlus && validForOralTrt) {
    return 'For clients who have not yet tried TRT, and have not been diagnosed with primary hypogonadism, we recommend starting with our EP-02 Protocol.'
  }

  if (validForEp02 && !validForOralTrtPlus && !validForOralTrt) {
    if ((!value1 || statesAvailable.includes(value1)) && value2 === 'boost' && (!value3 || value3 === 'yes')) {
      return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production and maintaining fertility.'
    }

    if (!statesAvailable.includes(value1) && (['exogenous', 'combination'].includes(value2))) {
      return 'Unfortunately our products that provide supplemental bioidentical testosterone (Oral TRT and Oral TRT+) are not yet available in your state. If you have not been diagnosed with primary hypogonadism, we recommend trying our EP-02 Protocol, which boosts your body\'s natural testosterone production without harming fertility.'
    }

    if (!statesAvailable.includes(value1) && !value2 && !value3) {
      return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production and maintaining fertility.'
    }

    if (!statesAvailable.includes(value1) && value2 === 'boost' && !value3) {
      return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production and maintaining fertility.'
    }

    return 'We recommend trying our EP-02 Protocol. It\'s a great solution for boosting your body\'s natural testosterone production.'
  }

  if (!validForEp02 && validForOralTrtPlus && !validForOralTrt) {
    if (!value1) {
      return '*Please note, our products that provide supplemental bioidentical testosterone (Oral TRT and Oral TRT+) are only currently available in the following states: AL, OH, FL, CA, TN, TX, MI, MD, NY, and WV. If you are not in one of those states and have not been diagnosed with hypogonadism, you\'ll likely want to try our EP-02 Protocol.'
    }

    if (statesAvailable.includes(value1) && value2 === 'combination' && !value3) {
      return 'We recommend our Oral TRT+ which is a combination therapy that both adds supplemental TRT as well as boosts your body\'s own natural testosterone production.'
    }

    return 'We recommend our Oral TRT+ Protocol which is a combination therapy. It adds exogenous testosterone and also boosts your body\'s natural testosterone production.'
  }

  if (!validForEp02 && !validForOralTrtPlus && validForOralTrt) {
    if (!value1) {
      return '*Please note, our products that provide supplemental bioidentical testosterone (Oral TRT and Oral TRT+) are only currently available in the following states: AL, OH, FL, CA, TN, TX, MI, MD, NY, and WV. If you are not in one of those states and have not been diagnosed with hypogonadism, you\'ll likely want to try our EP-02 Protocol.'
    }

    return 'We recommend our Oral TRT Protocol which is a convenient once-a-day pill for boosting testosterone.'
  }
}



